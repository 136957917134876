import React from 'react';
import './style/App.scss';

// Components
import Hero from './components/hero/hero';
import Portfolio from "./components/portfolio/portfolio";
import Contact from "./components/contact/contact";

class App extends React.Component {
  state = {
    userIsScrolled: false,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.userIsScrolled);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.userIsScrolled);
  }

  // Detect if user is scrolled down (used to add/disable extra large navbar)
  userIsScrolled = () => {
    if (window.pageYOffset > 80) {
      this.setState({ userIsScrolled: true });
    } else {
      this.setState({ userIsScrolled: false });
    }
  };

  render() {
    return (
      <div className="App">
        <div className="main-content">
          <Hero />
          <Portfolio />
          <Contact />
        </div>
      </div>
    );
  }
}

export default App;
