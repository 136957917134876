import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contactSocial.scss";

import FacebookIcon from "../../../assets/contact/facebook.svg";
import InstagramIcons from "../../../assets/contact/instagram.svg";

const ContactSocial = () => (
  <Row>
    <Col xs={12}>
      <div className="contact__social-container">
        <div className="contact__social">
          <a href="https://www.facebook.com/AntonsWorkshop" target="_blank" rel="noopener noreferrer">
            <img src={FacebookIcon} alt="Facebook" />
          </a>
        </div>
        <div className="contact__social">
          <a href="https://www.instagram.com/antonsworkshop/" target="_blank" rel="noopener noreferrer">
            <img src={InstagramIcons} alt="Instagram" />
          </a>
        </div>
      </div>
    </Col>
  </Row>
);

export default ContactSocial;
