import React from "react";
import Masonry from "react-masonry-css";
import "react-image-lightbox/style.css"; // Import CSS for the lightbox
import Lightbox from "react-image-lightbox";
// Scss
import "./portfolio.scss";
// Assets
import Preview1 from "../../assets/portfolio/project01/preview.png";
import Preview2 from "../../assets/portfolio/project02/preview.png";
import Preview3 from "../../assets/portfolio/project03/preview.png";
import Preview4 from "../../assets/portfolio/project04/preview.png";
import Preview5 from "../../assets/portfolio/project05/preview.png";
import Preview6 from "../../assets/portfolio/project06/preview.png";
import Preview7 from "../../assets/portfolio/project07/preview.png";
import Preview8 from "../../assets/portfolio/project08/preview.png";
import Preview9 from "../../assets/portfolio/project09/preview.png";
// Components
import Title from "../ui-components/title/title";
import ProjectBox from "../ui-components/projectBox/projectBox";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        { id: "1", preview: Preview1},
        { id: "2", preview: Preview2},
        { id: "3", preview: Preview3},
        { id: "4", preview: Preview4},
        { id: "5", preview: Preview5},
        { id: "6", preview: Preview6},
        { id: "7", preview: Preview7},
        { id: "8", preview: Preview8},
        { id: "9", preview: Preview9},
      ],
      filterResult: null,
      pickedFilter: "all",
      pickedFilterDropdown: "NEWEST",
      photoIndex: 0,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.filterGallery("all");
  }

  filterGallery = (target) => {
    let projectsArr = [...this.state.projects];
    let result;

    if (target !== "all") {
      result = projectsArr.filter((project) => project.tag === target);
    } else {
      result = projectsArr;
    }

    this.setState({ filterResult: result, pickedFilter: target });
  };

  filterDropDownHandler = (filter) => {
    this.setState({ pickedFilterDropdown: filter });

    let projectsArr = [...this.state.filterResult];
    let result;

    if (filter === "NEWEST") {
      result = projectsArr.sort((a, b) => (a.id > b.id ? 1 : -1));
    } else if (filter === "OLDEST") {
      result = projectsArr.sort((a, b) => (a.id > b.id ? 1 : -1)).reverse();
    }

    this.setState({ filterResult: result });
  }

  openLightbox = (index) => {
    this.setState({ photoIndex: index, isOpen: true });
  };

  closeLightbox = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { photoIndex, isOpen, filterResult } = this.state;

    const projectsRender = filterResult?.map((project, index) => (
      <ProjectBox
        preview={project.preview}
        key={project.id}
        onClick={() => this.openLightbox(index)}
      />
    ));

    const portfolioBreakpoints = {
      default: 3,
      1100: 3,
      700: 2,
      500: 1,
    };

    return (
      <div id="portfolio">
        <div className="wrapper">
          <Title title="WORK SHOWCASE" />
          <Masonry
            breakpointCols={portfolioBreakpoints}
            className="my-masonry-grid"
            columnClassName="mint__gallery"
          >
            {projectsRender}
          </Masonry>
          {isOpen && (
            <Lightbox
              mainSrc={filterResult[photoIndex].preview}
              nextSrc={filterResult[(photoIndex + 1) % filterResult.length].preview}
              prevSrc={filterResult[(photoIndex + filterResult.length - 1) % filterResult.length].preview}
              onCloseRequest={this.closeLightbox}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + filterResult.length - 1) % filterResult.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % filterResult.length,
                })
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default Portfolio;
