import React from "react";
import "./contact.scss";
import Title from "../ui-components/title/title";

import ContactSocial from './contactInfo/contactSocial';


class Contact extends React.Component {
  
  render() {
    return (
      <div id="contact">
        <div className="wrapper">
          <Title title="CONTACT ME" />
          <p className="font16">
          I’d love to hear from you! Whether you have a question, a project in mind, or just want to say hello, feel free to reach out.
          </p>
          <ContactSocial />
        </div>
      </div>
    );
  }
}

export default Contact;
