import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
// SCSS
import "./hero.scss";
// Assets
import HeroImage from '../../assets/hero/hero-image.png';
// Components
import Button from '../ui-components/button/button';

const Hero = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`hero ${isVisible ? 'animate' : ''}`} id="hero">
      <div className="wrapper">
        <Row>
          <Col md={12} lg={6}>
            <div className="hero-info">
              <h1 className="weight800 font60">
                Anton's Workshop
              </h1>
              <p className="font15">
                My belief is that exceptional design shouldn't come with a hefty price tag.
                <br />
                My mission is to provide high-quality, creative design services that are accessible to all, whether you're a small business owner, an entrepreneur, or someone with a unique vision.
                <br />
                Here’s a look at the design services I offer at affordable prices.
              </p>
              <Button label="CONTACT ME" target={"contact"} />
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="hero-image">
              <img src={HeroImage} alt="hero" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Hero;
