import React from "react";
// Scss
import "./projectBox.scss";

const ProjectBox = (props) => (
  <div className="portfolio__box" onClick={props.onClick}>
    <img src={props.preview} alt="project" />
    <div className="text-center">
      <p className="font30 weight800">{props.title}</p>
      <p className="font12 weight500">{props.tag}</p>
    </div>
  </div>
);

export default ProjectBox;
